import React, { useState, useRef, useEffect } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import ReactLoading from 'react-loading';
import axios from "axios";
// import Header from './Header'
// import config from "../config"
import exportValue from '../../apiconfig'

import Swal from 'sweetalert2'
import queryString from 'query-string';
import Sub_header from "../Sub_header";
// import Shipment_steps from './Shipment_steps'

const Payment_details = () => {
  let flag = true
//     let userDetail = localStorage.getItem('ship_rocket_user');
//   console.log("userDetail", userDetail);
//   let userData = {};
//   if (userDetail != null) {
//     userData = JSON.parse(userDetail)

//   }
let detail = {};

if (localStorage.getItem('admin_login')) {
  detail = JSON.parse(localStorage.getItem('admin_login'));
}

  const location = useLocation();
  let parsed = queryString.parse(location.search);
  console.log("parsed", parsed);
  console.log("state",location.state)
  let navigate = useNavigate();
  // console.log("location==>", location)
  const [commissionstate, setcommissionState] = useState({ commission: 0 ,customer_discount:0})
  const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})
  const [state, setState] = useState({});
  const [otherState, setOtherState] = useState({ submit_button: true });
  const [paid, setPaid] = useState({ onpaid: false, order: "" });
  const [error, setError] = useState(null);
  const [terms, setterms] = useState({ first_term: false, second_term: false, third_term: false });
  console.log("terms", terms)
  const [paymentoption, setpaymentoption] = useState({ paypal: false });
  const [walletoption, setwalletOption] = useState({ wallet: false });
  const[balancestate,setbalanceState] = useState({total_balance:[]})
  

  const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000
  })
  
  const paypalRef = useRef()
  useEffect(() => {
    get_wallet_balance()
    dimension_detail()
    carrierCommission()
  }, [])

  const dimension_detail = () =>{
    let full_api = `https://api.kourierwale.com/v.1.0.1/web/query/user/get_company_info_api`;
    let sendData = {};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }
  let dimensions = []
  if (Array.isArray(parsed.dimensions)) {
    dimensions = parsed.dimensions
    dimensions = dimensions.map((sub) => {

      console.log("dimensions------>",
        Object.assign({}, sub.split(',')))
      return Object.assign({}, sub.split(','))

    })
    //  console.log("arr",arr)
  } else {
    dimensions.push(parsed.dimensions)
    dimensions = dimensions.map((sub) => {

      console.log("dimensions------>",
        Object.assign({}, sub.split(',')))
      return Object.assign({}, sub.split(','))

    })
  }

  let items = []
  if (Array.isArray(parsed.items)) {
    items = parsed.items
    items = items.map((sub) => {

      console.log("items11------>",
        Object.assign({}, sub.split(',')))
      return Object.assign({}, sub.split(','))

    })
    //  console.log("arr",arr)
  } else {
    items.push(parsed.items)
    items = items.map((sub) => {

      console.log("items------>",
        Object.assign({}, sub.split(',')))
      return Object.assign({}, sub.split(','))

    })
  }
  console.log("items===== ",items)

  let total_weight = 0
  dimensions.map((sub) => {
    total_weight = Number(total_weight) + Number(sub[3])
  })
  console.log("total_weight", total_weight)

  
  
 
  // let carrier_commission = parseFloat((Number(parsed.total_amount)* Number(commissionstate.commission) / 100).toFixed(2))
  
  
let discount = parseFloat((Number(parsed.total_amount))*Number(commissionstate.customer_discount/100)).toFixed(2)
let price = parseFloat((Number(parsed.total_amount))- Number(discount) ).toFixed(2)

let tax_amount = parseFloat((Number(price) + Number(parsed.insurance))*18/100).toFixed(2)
  console.log("amount",tax_amount)

let total_paid_amount = parseFloat((Number(price)+ Number(parsed.insurance) + Number(tax_amount)) ).toFixed(2)
console.log("discount",discount)



  const submit_detail = async (type) => {
    console.log("type",type)
  
    if(flag==true){
      flag=false
    console.log("function")
    // console.log("userDetail", userData)
      let dataToSend = { sender_name: parsed.sender_name,
                        sender_mobile: parsed.sender_mobile,
                        sender_email: parsed.sender_email,
                        sender_address: parsed.sender_address,
                        sender_city: parsed.sender_city,
                        sender_city_id: parsed.sender_city_id,
                        sender_country_name: parsed.sender_country,
                        sender_country_code: parsed.sender_country_code,
                        sender_postal_code: parsed.sender_postal_code,
                        company_name: parsed.company_name,
                        receiver_name: parsed.receiver_name,
                        receiver_mobile: parsed.receiver_mobile,
                        receiver_email: parsed.receiver_email,
                        receiver_address: parsed.receiver_address,
                        receiver_city: parsed.receiver_city,
                        receiver_country_name: parsed.receiver_country,
                        receiver_country_code: parsed.receiver_country_code,
                        receiver_postal_code: parsed.receiver_postal_code,
                        sender_state_name:parsed.sender_state_name,
                        receiver_state_name:parsed.receiver_state_name,
                        return_name: parsed.return_name,
                        return_mobile: parsed.return_mobile,
                        return_email: parsed.return_email,
                        return_address: parsed.return_address,
                        return_city: parsed.return_city,
                        return_state_name: parsed.return_state_name,
                        return_country_name: parsed.return_country,
                        return_country_code: parsed.return_country_code,
                        return_postal_code: parsed.return_postal_code,
                        courier_id : parsed.courier_id,
                        payment_mode:parsed.payment_mode,
                        shipment_type:parsed.shipment_type,
                        cod_amount : parsed.cod_amount,
                        weight: "", length: "", height: "",
                        width: "", item_name:"",item_value:"",
                        product_type_name:parsed.product_type_name,
                        total_amount: total_paid_amount,
                        tax_amount: tax_amount,
                        rate_price : parsed.total_amount,
                        service_name: parsed.company_name,
                        carrier_id:parsed.carrier_id,
                        insurance_amount: parsed.insurance,
                        type:parsed.shipment_type,
                        carrier_id:parsed.carrier_id ,
                        address_id : parsed.sender_address_id,
                        order_id:parsed.order_id,
                        order_date:parsed.order_date,
                        mode:parsed.mode,
                        created_by:detail.admin_type==2?"Admin":"Staff",
                         total_weight:total_weight ,
                         payment_status : paid.order,
                         payment_type: type!=undefined ? type :0 }
                          
      dataToSend.item_name = items.map((sub)=>(
      sub[1]
      ))   
      dataToSend.item_value = items.map((sub)=>(
        
       sub[0]
      ))      
      dataToSend.quantity = items.map((sub)=>(
        
        sub[2]
       ))                   
                         
                         
      dataToSend.weight = dimensions.map((sub) => (
        sub[3]
      ))
      dataToSend.length = dimensions.map((sub) => (
        sub[2]
      ))
      dataToSend.height = dimensions.map((sub) => (
        sub[0]
      ))
      dataToSend.width = dimensions.map((sub) => (
        sub[1]
      ))
      dataToSend.cust_id = parsed.customer_id;
      console.log("datatoSend", dataToSend)

      let url = 'https://api.kourierwale.com/v.1.0.1/web/query/shipment/new_shipment_create';
      
      setOtherState({ ...otherState, submit_button: false })
      axios.post(url, dataToSend, { headers: exportValue.headers })
        .then((responseJson) => {
          console.log("responseJson => ", responseJson.data.output);
          setOtherState({ ...otherState, submit_button: true })
          if (responseJson.data.status == "success") {
          setTimeout(()=>{
                   navigate(`/shipments`, { replace: true, state: [] })
          },2000)
              
            Toast.fire({
            background:"#206bc4",
            type: 'unsuccess',
            title: "Shipment created successfully.",
            color:"white"
          });

          }
          else {
            setOtherState({ ...otherState, submit_button: true })
            Swal.fire({
              icon: 'error',
            
              text: responseJson.data.message,

            })
          }
        
        }).catch((error) => {
          setOtherState({ ...otherState, submit_button: true })
          console.error(error);
        });
      
      }
    }
    
  
  const paypalDesign = (pp) => {

    if (window.myButton) window.myButton.close();

    window.myButton = window.paypal.Buttons({
      createOrder: (data, actions) => {
        return actions.order.create({
          intent: "CAPTURE",
          purchase_units: [
            {
              description: "",
              amount: {
                currency_code: "USD",
                value: total_paid_amount
              },
            },
          ],
        });
      },
      onApprove: async (data, actions) => {
        const order = await actions.order.capture();
        setPaid({ ...paid, onpaid: true, order: order });
        console.log("onpaidstate", paid);

      },
      onError: (err) => {
        //   setError(err),
        console.error(err);
      },
    })

    window.myButton.render(paypalRef.current);
  }


  if (paid.onpaid == true) {
    console.log("adsasd")
    setPaid({ ...paid, onpaid: false })
    setOtherState({ ...otherState, submit_button: true })
    //  navigate('/payment_details'+parsed)
    submit_detail()
  }

  // If any error occurs
  if (error) {
    return <div className='text-center mt-5'><h3>Error Occurred in processing payment.! Please try again.</h3></div>;
  }


  const confirmterms = (e) => {
    console.log("eee", e.target.checked)
    console.log("eee", e.target.name)

    if (e.target.checked == true) {
      if (e.target.name == "first_term") {
        setterms({ ...terms, first_term: true })
      }
      else if (e.target.name == "second_term") {
        setterms({ ...terms, second_term: true })
      }
      else if (e.target.name == "third_term") {
        // if(userData.customer_id!=undefined){
        setterms({ ...terms, third_term: true })
        paypalDesign()
        // }
        // else{
        //   Toast.fire({
        //     background:"rgb(231, 91, 75)",
        //     type: 'unsuccess',
        //     title: "Please login",
        //     color:"white"
        //   });
        // }
      }
    }
    else {
      setterms({ ...terms, [e.target.name]: false })
    }
  }
  const payment_option = (e) => {
    console.log("eee", e.target.checked)
    console.log("eee", e.target.name)

    if (e.target.checked == true) {
      if (e.target.name == "wallet") {
        setwalletOption({ ...walletoption, wallet: true})
        setpaymentoption({ ...paymentoption, paypal: false})
      }
    
      else if (e.target.name == "paypal") {
       
        setpaymentoption({ ...paymentoption, paypal: true})
        setwalletOption({ ...walletoption, wallet: false})
        paypalDesign()
      
      }
    }
   
  }

  const get_wallet_balance=()=>{
    let dataToSend = {customer_id : parsed.customer_id};
   
     console.log("datatoSend",dataToSend)
     let url = 'https://api.kourierwale.com/v.1.0.1/web/query/user/get_wallet_balance';
     axios.post(url, dataToSend, { headers: exportValue.headers })
         .then((res) => {
           console.log("responseJson => ", res);
           if(res.data.status==true){
       setbalanceState({total_balance:res.data.find_customer_wallet})
    }
   else{
    window.location.reload(true);
       const Toast = Swal.mixin({
           toast: true,
           position: 'bottom-end',
           showConfirmButton: false,
           timer: 3000
         })
         Toast.fire({
           background:"#206bc4",
           type: 'Unsuccess',
           title: "Something Went Wrong !",
           color:"white"
         });
         
   } })
       
         .catch((error) => {
           const Toast = Swal.mixin({
               toast: true,
               position: 'bottom-end',
               showConfirmButton: false,
               timer: 3000
             })
             Toast.fire({
               background:"#206bc4",
               type: 'Unsuccess',
               title: "Something Went Wrong !",
               color:"white"
             });
             console.log(error);
         });    
   
   }

   const carrierCommission = () =>{
    let full_api = `https://api.kourierwale.com/v.1.0.1/web/query/user/carrier_commission`;
    let sendData = {carrier_id:parsed.carrier_id,customer_id : parsed.customer_id};
  
    axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      console.log("commission====>", res.data.commission);
      setcommissionState({commission:res.data.commission,customer_discount:res.data.customer_discount})

    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
  }

  return (
    <div>
        <Sub_header/>
      <section class="mb-3 mt-3">
  <div style={{marginLeft:"15px",marginRight:"15px"}}>

    {/* <?php include 'shipment_steps.php';?> */}
{/* <Shipment_steps value={4}/> */}

<div class="row">
            <div class="col-md bg-light p-5 ">
              <section class="pt-3">
                <h3 class="fs-5 pb-3">Basic information</h3>
                <div class="row">
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Sender</small>
                    <h4 class="fs-5">{parsed.sender_name}</h4>
                  </div>
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Receiver</small>
                    <h4 class="fs-5">{parsed.receiver_name}</h4>
                  </div>
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Pickup</small>

                    <h4 class="fs-6">{parsed.sender_address},{parsed.sender_city},{parsed.sender_state_name}</h4>
                    <p>{parsed.sender_country_name} - {parsed.sender_postal_code}</p>
                  </div>
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Delivery</small>
                    <h4 class="fs-6">{parsed.receiver_address}, {parsed.receiver_city},{parsed.receiver_state_name}</h4>
                    <p>{parsed.receiver_country_name} - {parsed.receiver_postal_code}</p>

                  </div>
                </div>
              </section>

              <hr class="m-4" />

              <section class="">
                <div class="row">
                  <div class="col-12 col-md">
                    <h3 class="fs-5 pb-3">Shipping Items</h3>
                    <div class="row">
                      <div class="col-md">
                       {items.map((sub)=>( <h4 class="fs-6">{sub[1]}</h4>))}
                        {dimensions.map((sub) => (
                          <p className="text-muted">{sub[3]} ({dimensionState.companyDetail.map((subscriber)=>( 
                            <>{subscriber.set_weight}</>
                           ))}) {sub[0]} x {sub[1]} x {sub[2]} ({dimensionState.companyDetail.map((subscriber)=>( 
                            <>{subscriber.set_dimension}</>
                           ))})</p>))}
                      </div>
                      <div class="col-md">
                        <small class="text-muted">Item(s)</small>

                        <h4 class="fs-5">{dimensions.length}</h4>

                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <hr class="m-4" />

              <section class="">
                <div class="row">
                  <div class="col-12 col-md">
                    <h3 class="fs-5 pb-3">Order details</h3>
                    <div class="row">
                      <div class="col-6 col-md-6">
                        <small class="text-muted">Mode</small>
                        <h4 class="fs-5">{parsed.company_name=="Fedex"?"International":"Domestic"}</h4>
                      </div>
                      <div class="col-6 col-md-6">
                        <small class="text-muted">Type</small>
                        <h4 class="fs-5">{parsed.shipment_type}</h4>
                      </div>
                      <div class="col-6 col-md-6">
                        <small class="text-muted">Service</small>
                        <h4 class="fs-5">{parsed.company_name}</h4>
                      </div>
                      <div class="col-6 col-md-6">
                        <small class="text-muted">Total Weight</small>
                        <h4 class="fs-5">{total_weight} ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <div class="col-12 col-6 col-md-6 p-5">
              <section class="">
                <h3 class="fs-5 pb-3">Pricing details</h3>
                <div class="row">
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Base Price</small>
                  </div>
                  <div class="col-6 col-md-6">
                    <h4 class="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{parsed.total_amount}</h4>
                  </div>
                  {/* <div class="col-6 col-md-6">
                    <small class="text-muted">Surcharge</small>
                  </div>
                  <div class="col-6 col-md-6">
                    <h4 class="fs-6">$0</h4>
                  </div> */}
                 
                
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Discount ({commissionstate.customer_discount}%)</small>
                  </div>
                  <div class="col-6 col-md-6">
                    <h4 class="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{discount}</h4>
                  </div>
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Insurance</small>
                  </div>
                  <div class="col-6 col-md-6">
                    <h4 class="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{parsed.insurance}</h4>
                  </div>
                  <div class="col-6 col-md-6">
                    <small class="text-muted">Tax (VAT 18%)</small>
                  </div>
                  <div class="col-6 col-md-6">
                    <h4 class="fs-6">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{tax_amount}</h4>
                  </div>
                </div>
              </section>
              <section class="">
              
                <div class="row pt-4">
                  <div class="col-6 col-md-6">
                    <h4 class="fs-4">Total Price</h4>
                  </div>
                  <div class="col-6 col-md-6">
                    <h4 class="fs-4">{dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{total_paid_amount}</h4>
                  </div>
                </div>
                
              </section>

              <hr class="m-4" />
              <section class="">
                <div class="col-12">
                  <h6>Terms &amp; Conditions</h6>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check1" name="first_term" onChange={(e) => confirmterms(e)} />
                    <label class="form-check-label" for="check1">
                      I agree to the terms and conditions and privacy policy and confirm that my shipment complies with the restricted items list.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check2" name="second_term" onChange={(e) => confirmterms(e)} />
                    <label class="form-check-label" for="check2">
                      I agree that if the dimensions and weight of my parcel(s) are incorrect, additional charges will be applied to my card based on information provided by the courier.
                    </label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="checkbox" id="check3" name="third_term" onChange={(e) => confirmterms(e)} />
                    <label class="form-check-label" for="check3">
                      I confirm that the value of goods that I have declared is correct.
                    </label>
                  </div>
                </div>
              </section>
              <hr class="m-4" />
              {terms.first_term == true && terms.second_term == true && terms.third_term == true ?
                <section class="payment_optionss">
                  <h5 class="text-dark fs-6">Select Payment Option</h5>
                  {/* <div class="card m-0 p-0 mb-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" checked="" />
                        </div>
                      </div>
                      <div class="ps-2 ">
                        <h6>Pay at Pickup</h6>
                      </div>
                    </div>
                  </div>
                </div> */}
                  <div class="card m-0 p-0 mb-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="wallet" id="inlineRadio1" value="option1" onChange={(e) => payment_option(e)} checked={paymentoption.paypal==false && walletoption.wallet==true} />
                        </div>
                      </div>
                      <div class="ps-2 ">
                        {balancestate.total_balance.map((sub)=>(<h6>Wallet (Balance: {dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{parseFloat(sub.wallet_balance).toFixed(2)})</h6>))}
                      </div>
                    </div>
                  </div>
                </div>

                  {/* <div class="card m-0 p-0 mb-2">
                    <div class="card-body">
                      <div class="d-flex">
                        <div class="pt-2">
                          <div class="form-check form-check-inline">
                            <input class="form-check-input" type="radio" name="paypal" id="inlineRadio1" onChange={(e) => payment_option(e)}  checked={paymentoption.paypal==true && walletoption.wallet==false} />
                          </div>
                        </div>
                        <div class="ps-2 ">
                          <img src="https://www.svgrepo.com/show/362018/paypal.svg" alt="" height="100px" />
                        </div>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="card m-0 p-0 mb-2">
                  <div class="card-body">
                    <div class="d-flex">
                      <div class="">
                        <div class="form-check form-check-inline">
                          <input class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                        </div>
                      </div>
                      <div class="ps-2 ">
                        <h6>Bank Transfer</h6>
                      </div>
                    </div>
                  </div>
                </div> */}
                </section> : ""}
              <section class="">
                <div class=" mt-3">

                  {/* {(otherState.submit_button) ? */}
                  <h3 class="" >Total Paid Amount {dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{total_paid_amount}</h3>
                  {/* <ReactLoading type={"spin"} color={"#dc291e"} height={40} width={40} /> */}
                  {/* } */}
                  {/* <a href="payment.php" class="btn btn-main btn-lg">Proceed to Payment $25</a> */}
                  {/* {terms.first_term==true && terms.second_term==true && terms.third_term==true? */}
                  {/* {terms.first_term==true && terms.second_term==true && terms.third_term==true ? */}
                  <div>
                    {/* <button onClick={()=>submit_detail()}>submit</button> */}
                    {(otherState.submit_button) ?
                        <>
                      <div class={terms.first_term == true && terms.second_term == true && terms.third_term == true && paymentoption.paypal==true && walletoption.wallet==false? "" : "d-none"} ref={paypalRef} >
                        {/* <a onClick={()=>submit_detail()}>Proceed to PAy</a> */}
                        </div>
                        
                        <div class=" mt-3">
                        <a   class={terms.first_term == true && terms.second_term == true && terms.third_term == true && paymentoption.paypal==false && walletoption.wallet==true? "btn btn-primary btn-lg" : "d-none"} onClick={()=>submit_detail(1)}>Proceed to Payment {dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}{total_paid_amount}</a>
                      </div>
                      
                        </>
                        :

                      <ReactLoading type={"spin"} color={"#dc291e"} height={40} width={40} />}


                   
                  </div>
                

                </div>
              </section>
            </div>
          </div>
  </div>
</section>


     
    </div>
  )
}

export default Payment_details

import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import exportValue from '../../apiconfig'
import Barcode from 'react-barcode';



const Print_label = () => {
    const location = useLocation()
    const {shipment_id} = useParams()
    console.log("shipment_id",shipment_id)

  

    const[state,setState]= React.useState({shipment_list:[] , total:0})
    console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})


    React.useEffect(() => {
      dimension_detail()
      shipment_detail()
    
      }, [])
   

      const shipment_detail=()=>{
        let dataToSend = {shipment_id:shipment_id };
   
      console.log("datatoSend",dataToSend)
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_detail`;
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(full_api, dataToSend, { headers: exportValue.headers })
          .then((res) => {
            console.log("responseJson => ", res);
            setState({shipment_list:res.data.output , total:res.data.total})
          
  
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              console.log(error);
          });    

    }
    const dimension_detail = () =>{

      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
 const  FunctionComponent = () => {
    return <Barcode value={shipment_id} />;
};
  return (
    <div>
      <div className="pagebreak" >
              <section class="labling_929393">
      <div className='row label-8x11-layout'>
         {state.shipment_list.map((sub)=>(
//       <section class="mb-3 pt-5 bg-light p-5  ">
//    <div class="lable">
//    <div class="card border-dark">
//      <div class="card-body">
//       <div class="d-flex justify-content-between border-oring">
     
//           <div class="">
//             <h4>TO</h4>
//             <h3>{sub.sender.name}</h3>
//             <h4>{sub.sender.address}</h4>
//             <p>{sub.sender.city}, {sub.sender.state}, {sub.sender.country} - {sub.sender.postal_code}</p>
//           </div>
      
      
//           <div class=" ">
//             <h6>Origin</h6>
//             <h1>{sub.sender.city.substring(0,3).toUpperCase()}</h1>
//             <small>Contact no. {sub.sender.mobile}</small>
//           </div>
     
//       </div>
//       <div class="d-flex justify-content-between pt-2">
     
//           <div class="">
//             <h4>FROM</h4>
//             <h5>{sub.receiver.name}</h5>
//             <p>{sub.receiver.address}</p>
//             <p>{sub.receiver.city}, {sub.receiver.state}, {sub.receiver.country} - {sub.receiver.postal_code}</p>
//           </div>
     
     
//           <div class=" ">
//             <h6>Destination</h6>
//             <h1>{sub.receiver.city.substring(0,3).toUpperCase()}</h1>
//             <small>Contact no. {sub.receiver.mobile}</small>
//           </div>
      
//       </div>
//       <hr/>
//       <div class="d-flex justify-content-between">
//           <div class="">
//             <p>Order Date: <strong> <Moment format="MMM DD YYYY">
//                         {new Date(sub.created*1000)}
//             </Moment> <br/>
//                             <small><Moment format="hh:mm">
//                         {new Date(sub.created*1000)}
//             </Moment></small></strong></p>
//             <p>Invoice #: <strong>{sub.order_number}</strong></p>
//           </div>
//           <div class=" ">
//           <FunctionComponent/><br/>
          
//           </div>
//       </div>
//       <hr/>
//       <div class="d-flex justify-content-between">
//           <div class="">
//             <h1>{sub.payment_mode.toUpperCase()}</h1>
//             {sub.payment_mode=="cod"?<h6>COD AMOUNT: {sub.cod_amount}</h6>:""}
//             <small>Total Weight: {sub.total_weight!=undefined && sub.total_weight!=""?sub.total_weight:""}({dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_weight}</>
//                                  ))})</small>
//           </div>
//           <div class=" ">
//             <small>{sub.service_provider}</small><br/>
//             <img src="https://i.imgur.com/I5kPgVW.png" class="img-fluid qrcode" /> <img src="https://i.imgur.com/3saCi40.png" class="img-fluid barcode" /><br/>
//             <small>AWB #: {sub.shipment_id}</small><br/>
//             {sub.items.map((item)=>(
//             <small>Dim: ({dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_dimension}</>
//                                  ))}): H {item.item_height} x W {item.item_width} x L {item.item_length}</small>
//             ))}
//           </div>
//       </div>
//       <hr/>
//       <table class="table table-bordered">
//           <thead>
//             <tr>
//               <th>ITEM NAME</th>
//               <th>QTY</th>
//               <th>AMOUNT</th>
//             </tr>
//           </thead>
          
//           <tbody>
//           {sub.items.map((item)=>(
//             <tr>
//               <td>{item.item_name}</td>
//               <td>{item.item_quantity}</td>
//               <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{item.item_value}</td>
//             </tr>
//                ))}
//             <tr>
//               <td></td>
//               <td>Order Total</td>
//               <td>{dimensionState.companyDetail.map((subscriber)=>( 
//                                   <>{subscriber.set_currency}</>
//                                  ))}{state.total}</td>
//             </tr>
//           </tbody>
       
//         </table>
//         <small>This is computer generated document,hence does not required signature</small>
//      </div>
//    </div>
//    </div>
//  </section>
<div className="col-12 col-md-5">
<section class="mb-3 sticky-top" >
       <div class="card border rounded-0 lable_layout_1" >
           <div class="card-body" >
               <div class="row">
                   <div class="col-9 col-md-9">
                       <h2>To:</h2>
                       <h3>{sub.receiver.name}</h3>
                       <h3>{sub.receiver.address}, {sub.receiver.city}, {sub.receiver.state}, {sub.receiver.country}</h3>
                        <h3>Mobile No: {sub.receiver.mobile}</h3> 
                       <h3>{sub.receiver.pincode}</h3>
                   </div>
                  
                       <div class="col-3 col-md-3">
                           {/* <img src={ `https://cdn.kourierwale.co.in/images/${sub.carrier_image}`} class="img-fluid logo"  height="10px"/> */}

                       </div>
                      
               </div>
               <hr />
               <div class="row mb-2">
                   <div class="col-6">
                       <h3>Order Date: <strong>{(sub.order_date)}</strong></h3>
                       <h3>Invoice No: <strong>{sub.order_number}</strong></h3>
                   </div>
                   <div class="col-6 text-end svg-container">
                   {dimensionState.companyDetail[0].hide_carrier?"":    <img src= { `https://cdn.kourierwale.co.in/images/${sub.carrier_image}`} class="img-fluid logo"  style={{height:"60px"}}/>}
                   {/* <Barcode value={sub.carrier_tracking_number} height={40} /> */}

                       {/* <h6>{sub.order_number}</h6> */}
                   </div>
               </div>
               <div className='row'>
                <div className='col-12 text-center'>
                     <Barcode value={sub.carrier_tracking_number} height={60} width={3} displayValue="false" /> 
                     <h6 style={{fontSize:"25px"}}>{sub.carrier_tracking_number}</h6>
                </div>
               </div>
               <hr />
               <div class="row mb-2">
                   <div class="col-6 col-md text-center">
                       <h5>{sub.payment_mode}</h5>
                       <h5>{sub.payment_mode=="prepaid"?"":'₹'+sub.cod_amount}</h5>
                       <small>WEIGHT: {sub.total_weight}KG</small>
                   </div>
                   <div class="col-6 col-md text-center">
                       <h6>{sub.service_provider}</h6>
                       <h6>{sub.carrier_tracking_number?sub.carrier_tracking_number:""}</h6>
                       <small>Dimensions (cm): {sub.height}X {sub.width}X {sub.length}</small>
                   </div>
               </div>

               <div class="table-responsive mb-2">
                   <table class="table table-bordered border">
                       <thead>
                           <tr>
                              <th>Item Name</th> 
                             <th>Qty.</th> 
                             <th>Amount</th> 
                           </tr>
                       </thead>
                       <tbody>
                       {sub.items.map((item)=>(

                          <tr>
                        <td>{item.item_name}</td>
                           <td>{item.item_quantity}</td> 
                          <td>&#8377;{item.item_value}</td> 
                          </tr>
                          ))}
                                  

                          

                       </tbody>
                   </table>
               </div>
             
               <div class="mb-2">
                   <h2>Pickup Address:</h2>
                 <h4>{sub.warehouse_id?sub.warehouse_id:sub.sender.name}</h4> 
                   <h3>{sub.sender.address} {sub.sender.city}, {sub.sender.state}, {sub.sender.country} - {sub.sender.pincode}</h3> 
                   <h3>Mobile No: {sub.sender.mobile}</h3>
               </div>

             
               <div class="mb-2">
                   <h2>Return Address:</h2>
                  <h4>{sub.warehouse_id?sub.warehouse_id:sub.return[0].name}</h4> 
                   <h3>{sub.return[0].address} {sub.return[0].city}, {sub.return[0].state}, {sub.return[0].country} - {sub.return[0].pincode}</h3> 
                   <h3>Mobile No: {sub.return[0].mobile}  </h3>
               </div>


               {/* <div class="mb-3">
                   <h2>For any query please contact:</h2>
                   <h3>{state.customer_label_setting.show_support_email_mobile ? `Mobile: ${state.customer_label_setting.support_mobile ? state.customer_label_setting.support_mobile : ""}, Email: ${state.customer_label_setting.support_email ? state.customer_label_setting.support_email : ""}` : ""}</h3>
               </div> */}

               <hr />
               <div className='custom-row'>
<div>
<small>Powered By Kourier Wale</small>
</div>
<div>
<img src='../../../../assets/img/labels_icon_kw.png' width="100px" alt="Logo" />
</div>
</div>

           </div>
       </div>

   </section>
</div>

         ))}
         
         </div>
         </section>
         </div>   
    </div>
  )
}

export default Print_label

import React, { useEffect,useState } from 'react'
import Left_panel from '../Left_panel'
import Sub_header from '../Sub_header'
import axios from 'axios'
import exportValue from '../../apiconfig'
import Swal from 'sweetalert2'
import Modal from 'react-bootstrap/Modal';
import ReactLoading from 'react-loading';
import Moment from 'react-moment';
import '../../components/loader.css';
import TablePagination from '@mui/material/TablePagination';
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import { useLocation, useNavigate } from 'react-router-dom'
import download from 'downloadjs'
import { DateRangePicker } from 'react-date-range';
import { addDays } from "date-fns";
import * as XLSX from 'xlsx'
// import 'react-date-range/dist/styles.css'; // main style file
// import 'react-date-range/dist/theme/default.css';


const Shipment_report = () => {
  let navigate = useNavigate();
   const[date,setDate]=React.useState({start_date:"" , end_date:""})
  
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
    const homeSection = document.querySelector('.home-section');
    if (homeSection) {
        homeSection.classList.toggle('sidebar-closed');
    }
};

  useEffect(() => {
    const customerId = queryParams.get('customer_id');
    if (customerId) {
      console.log('Customer ID:', customerId);
      let full_api1 = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_id_profile`;
      let sendData1 = {customer_id:customerId};
    
      axios.post(full_api1, sendData1, { headers: exportValue.headers }).then(res => {
        // setdimensionState(res.data.output);
        setRState({...rstate , text:res.data.output[0].full_name ,   customer_id:res.data.output[0].customer_id})
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    
      setIsLoading(true)
      let sendData = {
      //   indexValue: index,
      //   limit: perPage,
        from_start_date: xcc,
        to_start_date: ycc,
        customer_id:customerId,
        status:searchfilters.filterStatus,
       
      };
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_reports`;
      
  console.log("sendData",sendData)
  axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
      // setdimensionState(res.data.output);
      if(res.data.status==true){
          setIsLoading(false)
          setShipstate(res.data.output)
          setShipcount({count:res.data.count , amount:res.data.amount})
      }
      
      console.log("rnmmmmm", res);
    }).catch((e) => {
      // toast.configure()
       //toast.error("Some thing went wrong")
      console.log("----error:   ", e);
    })
    
    }
    else{
      console.log("not have ====")
    }
  }, [location]);
 
  const [state, setState] = useState([
      {
        startDate: new Date(),
        endDate: addDays(new Date(), 0),
        key: "selection",
        isOpen: false,
      },
    ]);
    console.log("state === " ,  state)
    var months = [
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
    ];
    var days = [
      "00",
      "01",
      "02",
      "03",
      "04",
      "05",
      "06",
      "07",
      "08",
      "09",
      "10",
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "19",
      "20",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "29",
      "30",
      "31",
    ];
  
    const xcc =
      state[0].startDate.getFullYear() +
      "-" +
      months[state[0].startDate.getMonth()] +
      "-" +
      days[state[0].startDate.getDate()];
    const ycc =
      state[0].endDate.getFullYear() +
      "-" +
      months[state[0].endDate.getMonth()] +
      "-" +
      days[state[0].endDate.getDate()];
const [shipmentstate, setshipStatus] = React.useState({ shipment_status: null });
const[modals,setModals] = React.useState({show: false,invoice_id:""})
const [otherStates,setOtherState] = React.useState({activePage:1,rowsPerPage:10,page:0,total_count:0,onload:true});

const [searchfilters, setsearchFilters] = React.useState({ filterStatus:[]})
console.log("searchfilters == " , searchfilters)
const[dimensionState,setdimensionState] = React.useState([])
console.log("dimensionState= " , dimensionState)
const [isLoading, setIsLoading] = useState(false);
const[shipstate, setShipstate]=useState([])
const[shipcount, setShipcount]=useState({count:0 , amount:0})

const [rstate, setRState] = useState({
  suggestions: [],
  text: "",
  customer_id:""
});
console.log("rstate= " , rstate)



useEffect(() => {   
customerProfile()
}, [])
const filter=(e,values)=>{
  const name = e.target.name;
  const value = values;
  const isChecked = e.target.checked;

  // Create a new array by spreading the existing filterStatus array
  const updatedFilterStatus = [...searchfilters.filterStatus];

  if (isChecked) {
    updatedFilterStatus.push(value);
  } else {
    // Remove the value from the array if unchecked
    const index = updatedFilterStatus.indexOf(value);
    if (index !== -1) {
      updatedFilterStatus.splice(index, 1);
    }
  }

  // Update the searchfilters state with the updated filterStatus array
  setsearchFilters((prevFilters) => ({
    ...prevFilters,
    filterStatus: updatedFilterStatus,
  }));
}




 

   const customerProfile =()=>{
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/customer_profile`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        setdimensionState(res.data.output);
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
   }

     const customerHandle =(e)=>{
      const value = e.target.value;
      let suggestions = [];
      if (value.length > 0) {
        const regex = new RegExp(`^${value}`, "i");
        dimensionState.map((sub)=>{
          if(sub.email.match(regex)!=null){
              suggestions.push(sub)
          }
      })
      //   suggestions = dimensionState.sort().filter((full_name) => regex.test(full_name));
        console.log("suggestions ==" , suggestions)
        setRState(() => ({
          suggestions,
          text: value,
        }));

      }
      else{
        setRState(() => ({
          text: "",
          customer_id:"",
          suggestions: [],
        }));
      }
    
     }
     
   
      
      function selectedText(value) {
        const currentUrl = new URL(window.location.href);
currentUrl.searchParams.delete('customer_id'); // Remove the 'customer_id' parameter
currentUrl.searchParams.set('customer_id', value.customer_id); // Set the new 'customer_id' parameter

// Update the URL
window.history.replaceState({}, '', currentUrl.toString());
          setRState(() => ({
            text: value.full_name,
            customer_id:value.customer_id,
            suggestions: [],
          }));
      }

      const getData = () => {
          setIsLoading(true)
          const currentDate = new Date();
const startDate = date.start_date !== "" ? date.start_date : currentDate.toISOString().slice(0,10);
const endDate = date.end_date !== "" ? date.end_date : currentDate.toISOString().slice(0,10);
          let sendData = {
          //   indexValue: index,
          //   limit: perPage,
            from_start_date: startDate,
            to_start_date: endDate,
            customer_id:rstate.customer_id,
            status:searchfilters.filterStatus,
           
          };
          let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_reports`;
          
      console.log("sendData",sendData)
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
          // setdimensionState(res.data.output);
          if(res.data.status==true){
              setIsLoading(false)
              setShipstate(res.data.output)
              setShipcount({count:res.data.count , amount:res.data.amount})
          }
          
          console.log("rnmmmmm", res);
        }).catch((e) => {
          // toast.configure()
           //toast.error("Some thing went wrong")
          console.log("----error:   ", e);
        })
        };
        const transformDataForExcel = () => {
          return shipstate.map(item => {
            console.log("created == ", item.created)
            const formattedDate = item.created
              ? new Date(item.created * 1000).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              })
              : '';
      
            const formattedTime = item.created
              ? new Date(item.created * 1000).toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                hour12: true,
              })
              : '';
            return {
              'Tracking Id': item?.carrier_tracking_number || '',
              'Reference Number': item?.order_number || '',
              'Pickup Warehouse': item?.warehouse_id || item.warehouse_name ||"",
              'Order Mode': item?.channel_name || "Custom-Single",
              'Receiver Name': item?.receiver[0]?.name || '',
              'Address': item?.receiver[0]?.address || '',
              'Mobile Number': item?.receiver[0]?.mobile || '',
              'City': item?.receiver[0]?.city || '',
              'State': item?.receiver[0]?.state || '',
              'Pincode': item?.receiver[0]?.pincode || '',
              'Carrier': item?.carrier_name || '',
              'Mode':  item?.product_type_name || '',
              'Carrier Product': item?.service_provider || '',
              'Current Status': getStatusText(item?.status) || '',
              'Weight (kg)': item?.total_weight || '',
              'L': item?.length || '',
              'B':item?.width || '',
              'H':item?.height || '',
              'Volumetric Weight (kg)': item?.volumetric_weight || '',
              'Chargeable Weight': item?.dead_weight || '',
              'Shipping Fee (₹)': item?.total_amount || '',
              'Shipment type': item?.type || '',
              'Payment Mode': item?.payment_mode === 'cod' || item?.payment_mode === 'cash' ? 'COD' : item?.payment_mode || '',
              'COD Amount': item?.cod_amount || '',
              'Zone Name':item?.zone_name||'',
              'Date': formattedDate,
              'Time': formattedTime,
            }
          });
        };
      
        const getStatusText = (status) => {
          // Define your status mappings here
          const statusMappings = {
            0: 'Pending',
            10: 'RTO',
            1: 'Pick Up Awaited',
            2: 'Picked Up',
            3: 'In Transit',
            4: 'Out For Delivery',
            5: 'Delivered',
            6: 'Cancelled',
            7: 'Failed',
            8: 'NDR',
            9: 'Pickup Scheduled',
      
          };
      
      
          return statusMappings[status] || '';
        }
        // const { onDownload } = useDownloadExcel({
        //   data: transformDataForExcel(),
        //   filename: 'Reports Table',
        //   sheet: 'Shipment Reports'
        // });
        const downloadExcel = () => {
          const data = transformDataForExcel();
          const ws = XLSX.utils.json_to_sheet(data);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Shipment Reports');
          XLSX.writeFile(wb, 'Reports Table.xlsx');
        };

        const handleDate =(e)=>{
          setDate({...date , [e.target.name]:e.target.value})
        }
  return (
//     <div>
//     <Sub_header/>
//     <div style={{marginLeft:"15px",marginRight:"15px"}}>
//      <section class="mt-3">
//     <div class="row">
//         <div class="col-12 col-md-2">
//             {/* <label>Customer Name</label> */}
//             <input className='form-control' name="customer_name" placeholder='Enter Customer Name' onChange={(e)=>customerHandle(e)} value={rstate.text} autoComplete='off'/>
//             <ul>
//             {rstate.suggestions.map((item, index) => (
//             <li 
//             className="form-control"
//             value={rstate.text}
//             key={index}
//           onClick={() => selectedText(item)}
//             style={{
//                 listStyle:'none',
//                 backgroundColor: "rgba(0, 0, 0, 0.05)",
//                 border: "1px solid #CCC",
//                boxShadow: "inset 1px 1px 0 rgba(255, 255, 255, 0.5)",
//                cursor: "pointer",
//                borderRadius: "3px",
//                padding: "10px 15px 10px 25px",
                
//             }}>
//                 {item.full_name} , {item.email}
//             </li>
//             ))}
//           </ul>
//         </div>
//         <div class="col-12 col-md-2">
//         <div class=" btn-toolbar mb-2 mb-md-0" style={{marginLeft:"30%"}}>
               
//                <div class="btn-group">
//                    <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
//                        Filter <i class="fa fa-angle-down" aria-hidden="true"></i>
//                    </button>
//                    <div class="dropdown-menu">
//                        <section class="p-2">
//                        <h6>Filter</h6>
//                        <hr/>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
//                                <label class="form-check-label">Pickup Awaited</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
//                                <label class="form-check-label">Pickup Scheduled</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
//                                <label class="form-check-label">Picked Up</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
//                                <label class="form-check-label">In Transit</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
//                                <label class="form-check-label">Out for Delivery</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
//                                <label class="form-check-label">Delivered</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
//                                <label class="form-check-label">Cancelled</label>
//                            </div>
//                            <div class="form-check">
//                                <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something"  onChange={(e)=>filter(e,7)}/>
//                                <label class="form-check-label">Failed</label>
//                            </div>
//                        </section>
                     
//                    </div>
//                  </div>

//            </div>
//         </div>
//         <div className='col-12 col-md-2'>
      
//         {/* <div class="form-group no-printme"> */}
//                           <button
//                             type="button"
//                             class="btn btn-block btn-primary btn-icon text-white "
//                             // style={{ width: "120px", marginLeft: "100%" }}
//                             data-bs-toggle="modal"
//                             data-bs-target="#bd-example-modal-lg"
//                           >
//                             Select Date
//                           </button>
                         
                         
//                           <div
//                            class="modal modal fade bd-example-modal-lg"
//                            id="bd-example-modal-lg" 
//                            tabindex="-1"
//                            role="document"
//                            aria-labelledby="myLargeModalLabel"
//                            aria-hidden="true"
//                           >
//                             <div class="modal-dialog modal-lg" role="document">
//                               <div class="modal-content">
//                                 <div class="modal-body">
//                                   <DateRangePicker
//                                     onChange={(item) =>
//                                       setState([item.selection])
//                                     }
//                                     showSelectionPreview={true}
//                                     moveRangeOnFirstSelection={false}
//                                     months={1}
//                                     ranges={state}
//                                     showPreview={false}
//                                     direction="horizontal"
//                                   />
//                                 </div>
//                                 <div class="modal-footer">
//                                   <button
//                                     type="button"
//                                     class="btn btn-secondary"
//                                     data-bs-dismiss="modal"
//                                   >
//                                     Close
//                                   </button>
//                                   {/* <button
//                                     type="button"
//                                     class="btn btn-primary"
//                                     data-bs-dismiss="bd-example-modal-lg"
//                                     // onClick={() => {getData(0); setloadingState({...loading,isLoading:true});}}
//                                   >
//                                     Get Report
//                                   </button> */}
//                                 </div>
//                               </div>
//                             </div>
//                           </div>
//                         {/* </div> */}
                      
//         </div>

//         <div className='col-md-2'>
//            <button className='btn btn-primary' onClick={(e)=>getData()}>Get Report</button> 
//         </div>
       
        
     
//     </div>
//     <div className='mt-5'>
//     {(isLoading) ?
//             <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
//                 <div style={{ left: "47%", top: "45%", position: "absolute" }}>
//                     <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
//                 </div>
//             </div> : 
       
//              <div class="card card-body border-0 shadow table-wrapper table-responsive mt-3">
             
           
//             <table class="table table-hover table-bordered shipm_tablew">
//                 <thead>
//                     <tr>
//                         <th class="border-gray-200">Shipment Id #</th>	
//                         <th class="border-gray-200">Origin</th>
//                         <th class="border-gray-200">Destination</th>	
//                         <th class="border-gray-200 ship_pickup">Sender</th>
//                         <th class="border-gray-200">Receiver</th>
//                         <th class="border-gray-200">Amount</th>
//                         <th class="border-gray-200">Carrier</th>
//                         {/* <th class="border-gray-200">Created By</th> */}
//                         <th class="border-gray-200">Status</th>
                      
//                     </tr>
//                 </thead>
//                { shipstate.map((item)=>
//                 <tbody>
                    
//                         <tr>
//                             <td>
//                                 <a href={"/shipment_detail/"+item.shipment_id} class="text-info fw-700 fs-6">{item.shipment_id}</a> <br/>
//                                 <span class="fw-normal"><Moment format="MMM DD YYYY hh:mm">
//                         {new Date(item.created*1000)}
//             </Moment> 
//             {/* <small>12:23</small> */}
//             </span>
//                             </td>                        
//                             <td>
//                             {item.sender[0].city}    <br/>
//                             <small>{item.sender[0].country}</small>
//                             </td>                        
//                             <td>
//                             {item.receiver[0].city}    <br/>
//                             <small>{item.receiver[0].country}</small>
//                             </td>
//                             <td class="">
//                                 <strong>{item.sender[0].name}</strong>
//                             </td>
//                             <td>
//                                <strong>{item.receiver[0].name}</strong>
//                             </td>
//                             <td class="fw-700 text-danger">
//                             ₹{item.total_amount}
//                             </td>
//                             <td>
//                                 <strong>{item.carrier_name}</strong>
//                                 {/* <img src={`https://cdn.shreebanshidharlogistics.com/images/${item.carrier_image}`} alt="" class="img-fluid"/> */}
//                             </td>
//                             {/* <td>{item.created_by?item.created_by:""}</td> */}
                        
//                             {item.status==0?<span class="badge rounded-pill bg-warning fw-400">Pending</span>:item.status==10?<span class="badge rounded-pill bg-danger fw-400">Not Picked</span>:item.status==1?<span class="badge rounded-pill bg-success fw-400">Pick Up Awaited</span>:item.status==2?<span class="badge rounded-pill bg-warning fw-400">Picked Up</span>:item.status==3?<span class="badge rounded-pill bg-danger fw-400">In Transit</span>:item.status==4?<span class="badge rounded-pill bg-primary fw-400">Out For Delivery</span>:item.status==5?<span class="badge rounded-pill bg-success fw-400">Delivered</span>:item.status==6?<span class="badge rounded-pill bg-danger fw-400">Cancelled</span>:item.status==7?<span class="badge rounded-pill bg-secondary fw-400">Failed</span>:item.status==8?<span class="badge rounded-pill bg-warning fw-400">NDR</span>:item.status==9?<><span class="badge rounded-pill bg-danger fw-400">Pickup Scheduled</span><br/><br/>
//                             {item.pickup_date!=undefined && item.pickup_date!=""?  <small>on {item.pickup_date!=undefined?item.pickup_date:""} at {item.pickup_time!=undefined?item.pickup_time:""}</small>:""} </>:<span class="badge rounded-pill bg-danger fw-400">Not Available</span>}
                           
//                         </tr>
//                       </tbody>
                    
//                )}
//                  <tr>
//                             <th>Total Shipments ({shipcount.count})</th>
//                             <th></th>
//                             <th></th>
//                             <th></th>
//                             <th></th>
//                             <th> ₹{shipcount.amount}</th>
//                             <th></th>
//                             <th></th>


//                         </tr>

//             </table>
            
//             {/* <TablePagination
//                         component="div"
//                         rowsPerPageOptions={[5, 10]}
//                         count={otherStates.total_count}
//                         page={otherStates.page}
//                         onPageChange={handlePageChange}
//                         rowsPerPage={otherStates.rowsPerPage}
//                         onRowsPerPageChange={handleChangeRowsPerPage}

//                     /> */}
                    
                  
//                     </div> 
                    
//  }
//           </div>
                

// </section>
// </div>

//     </div>
<div>
{/* <?php
            include('header.php');
        ?> */}

        {/* <?php
            include('left_nav.php');
        ?> */}
       <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
    <section class="home-section">
            {/* <?php
            include('sub_header.php');
            ?> 
         */}
    <Sub_header/>
            
        <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-2">
                <h5>Shipment Report</h5>
            </div>
            <div class="col-12 col-md">
                <div class="row">
                    <div class="col-12 col-md-4">
                        <input type="text" class="form-control"  name="customer_name" placeholder='Enter Customer Name' onChange={(e)=>customerHandle(e)} value={rstate.text} autoComplete='off'/>
            <ul>
            {rstate.suggestions.map((item, index) => (
            <li 
            className="form-control"
            value={rstate.text}
            key={index}
          onClick={() => selectedText(item)}
            style={{
                listStyle:'none',
                backgroundColor: "rgba(0, 0, 0, 0.05)",
                border: "1px solid #CCC",
               boxShadow: "inset 1px 1px 0 rgba(255, 255, 255, 0.5)",
               cursor: "pointer",
               borderRadius: "3px",
               padding: "10px 15px 10px 25px",
                
            }}>
                {item.full_name} , {item.email}
            </li>
            ))}
          </ul>
                    </div>
                    <div class="col-12 col-md">
                    <div class="btn-group">
                   <button type="button" class="btn btn-primary dropdown-toggle" data-bs-toggle="dropdown"><i class="fa fa-filter" aria-hidden="true"></i> &nbsp;
                       All Status <i class="fa fa-angle-down" aria-hidden="true"></i>
                   </button>
                   <div class="dropdown-menu">
                       <section class="p-2">
                       <h6>All Status</h6>
                       <hr/>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_awaited" value="something"  onChange={(e)=>filter(e,1)}/>
                               <label class="form-check-label">Pickup Awaited</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_scheduled" value="something" onChange={(e)=>filter(e,9)}/>
                               <label class="form-check-label">Pickup Scheduled</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="picked_up" value="something"  onChange={(e)=>filter(e,2)}/>
                               <label class="form-check-label">Picked Up</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="pickup_failed" value="something"  onChange={(e)=>filter(e,17)}/>
                               <label class="form-check-label">Pickup Failed</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="in_transit" value="something"  onChange={(e)=>filter(e,3)}/>
                               <label class="form-check-label">In Transit</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="out_for_delivery" value="something"  onChange={(e)=>filter(e,4)}/>
                               <label class="form-check-label">Out for Delivery</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="delivered" value="something"  onChange={(e)=>filter(e,5)}/>
                               <label class="form-check-label">Delivered</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="cancelled" value="something"  onChange={(e)=>filter(e,6)}/>
                               <label class="form-check-label">Cancelled</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="failed" value="something"  onChange={(e)=>filter(e,7)}/>
                               <label class="form-check-label">Failed</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="rto" value="something"  onChange={(e)=>filter(e,10)}/>
                               <label class="form-check-label">RTO</label>
                           </div>
                           <div class="form-check">
                               <input class="form-check-input" type="checkbox" id="check1" name="rto_delivered" value="something"  onChange={(e)=>filter(e,16)}/>
                               <label class="form-check-label">RTO Delivered</label>
                           </div>
                       </section>
                     
                   </div>
                 </div>
                    </div>
                    <div class="col-12 col-md-2">
                        <input type="date" class="form-control" id="text" placeholder="select date range" name="start_date" onChange={(e)=>handleDate(e)}/>
                    </div>
                    <div class="col-12 col-md-2">
                        <input type="date" class="form-control" id="text" placeholder="select date range" name="end_date" onChange={(e)=>handleDate(e)}/>
                    </div>
                    <div class="col-12 col-md-2 text-end">
                        <div class="d-grid">
                          <a onClick={(e)=>getData()} class="btn btn-primary btn-block">Get Report</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {(isLoading) ?
            <div style={{ backgroundColor: "#f1f5f9", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.4" }}>
                <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                    <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                </div>
            </div> : 
       
             <div class="card card-body border-0 shadow table-wrapper table-responsive mt-3">
             
           
            <table class="table table-hover table-bordered shipm_tablew">
                <thead>
                    <tr>
                        <th class="border-gray-200">Shipment Id #</th>	
                        <th class="border-gray-200">Origin</th>
                        <th class="border-gray-200">Destination</th>	
                        <th class="border-gray-200 ship_pickup">Sender</th>
                        <th class="border-gray-200">Receiver</th>
                        <th class="border-gray-200">Amount</th>
                        <th class="border-gray-200">Carrier</th>
                        {/* <th class="border-gray-200">Created By</th> */}
                        <th class="border-gray-200">Status</th>
                      
                    </tr>
                </thead>
               { shipstate.map((item)=>
                <tbody>
                    
                        <tr>
                            <td>
                                <a href={"/shipment_detail/"+item.shipment_id} class="text-info fw-700 fs-6">{item.shipment_id}</a> <br/>
                                <span class="fw-normal"><Moment format="MMM DD YYYY hh:mm">
                        {new Date(item.created*1000)}
            </Moment> 
            {/* <small>12:23</small> */}
            </span>
                            </td>                        
                            <td>
                            {item.sender[0].city}    <br/>
                            <small>{item.sender[0].country}</small>
                            </td>                        
                            <td>
                            {item.receiver[0].city}    <br/>
                            <small>{item.receiver[0].country}</small>
                            </td>
                            <td class="">
                                <strong>{item.sender[0].name}</strong>
                            </td>
                            <td>
                               <strong>{item.receiver[0].name}</strong>
                            </td>
                            <td class="fw-700 text-danger">
                            ₹{item.total_amount}
                            </td>
                            <td>
                                <strong>{item.carrier_name}</strong>
                                {/* <img src={`https://cdn.shreebanshidharlogistics.com/images/${item.carrier_image}`} alt="" class="img-fluid"/> */}
                            </td>
                            {/* <td>{item.created_by?item.created_by:""}</td> */}
                        
                            {item.status==0?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pending</span>:item.status==10?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> RTO</span>:item.status==16?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> RTO Delivered</span>:item.status==15?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Booked</span>:item.status==1?    <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pickup Awaited</span>:item.status==2?  <span class="status pickedup"> <i class="fa fa-check" aria-hidden="true"></i> Picked Up</span>:item.status==3?  <span class="status in_transit"> <i class="fa fa-truck" aria-hidden="true"></i> In Transit</span>:item.status==4?   <span class="status out_f_deli"> <i class="fa fa-plane" aria-hidden="true"></i> Out for Delivery</span>:item.status==5?  <span class="status delivered"> <i class="fa fa-check-square-o" aria-hidden="true"></i> Delivered</span>:item.status==6?    <span class="status cancelled"> <i class="fa fa-ban" aria-hidden="true"></i> Cancelled</span>:item.status==7?    <span class="status failed"> <i class="fa fa-exclamation-circle" aria-hidden="true"></i> Failed</span>:item.status==8?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> NDR</span>:item.status==17?  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Pickup Failed</span>:item.status==9?<>   <span class="status pickup_sechdls"> <i class="fa fa-calendar-o" aria-hidden="true"></i> Pickup Scheduled</span><br/><br/>
                            {item.pickup_date!=undefined && item.pickup_date!=""?  <small>on {item.pickup_date!=undefined?item.pickup_date:""} at {item.pickup_time!=undefined?item.pickup_time:""}</small>:""} </>:  <span class="status pickup_awit"> <i class="fa fa-clock-o" aria-hidden="true"></i> Not Available</span>}
                           
                        </tr>
                      </tbody>
                    
               )}
                 <tr>
                            <th>Total Shipments ({shipcount.count})</th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th> ₹{shipcount.amount}</th>
                            <th></th>
                            <th></th>


                        </tr>

            </table>
            
            {/* <TablePagination
                        component="div"
                        rowsPerPageOptions={[5, 10]}
                        count={otherStates.total_count}
                        page={otherStates.page}
                        onPageChange={handlePageChange}
                        rowsPerPage={otherStates.rowsPerPage}
                        onRowsPerPageChange={handleChangeRowsPerPage}

                    /> */}
                    
                  
                    </div> 
                    
 }
            {/* <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Date</th>
                            <th class="border-gray-200">AWB #</th>	
                            <th class="border-gray-200">Order #</th>
                            <th class="border-gray-200">Customer</th>
                            <th class="border-gray-200">Origin</th>
                            <th class="border-gray-200">Destination</th>	
                            <th class="border-gray-200 ship_pickup">Sender</th>
                            <th class="border-gray-200">Receiver</th>
                            <th class="border-gray-200">Carrier</th>
                            <th class="border-gray-200">Product</th>
                            <th class="border-gray-200">Amount</th>
                            <th class="border-gray-200">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        
                        <tr>
                            <td>
                                12/08/2022 <small>12:23</small>
                            </td> 
                            <td>
                                98765432
                            </td>  
                            <td>
                                234568765
                            </td>  
                            <td>
                                New India Trading Company
                            </td>                      
                            <td>
                                London, United Kingdom
                            </td>                        
                            <td>
                                New York, United Stats 
                            </td>
                            <td class="">
                                Mukesh Kumar Saini
                            </td>
                            <td>
                                Mohan Prakash Kumawat
                            </td>
                            <td>
                                Delhivery
                            </td>
                            <td>
                                Delhivery Less then 1KG
                            </td>
                            <td class="fw-700">
                                $89.09
                            </td>
                            <td>
                                Delivered
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>  */}
            <div class="mt-3 text-end">
                <a onClick={downloadExcel} class="btn btn-outline-primary">Download Report</a>
            </div>

    </section>
                    

    </section>


{/* <?php
include('footer.php');
?>   */}
</div>
  )
}

export default Shipment_report

import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import ReactLoading from 'react-loading';
import moment from 'moment'

const Customer_invoices = () => {
    let navigate = useNavigate()
    const[state , setState ] = React.useState([])
    const[isLoading , setisLoading] = React.useState(true)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);
    const[date, setdate] = React.useState({from_date:moment().subtract(30, 'days').format('YYYY-MM-DD'),to_date:moment().format('YYYY-MM-DD')})
    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    React.useEffect(()=>{
        invoice_list()
    },[])
    const invoice_list=(status,index=0,onLoad)=>{
        let dataToSend = {
    };
    if(date.from_date!="" && date.to_date!=""){
        dataToSend.from_date = date.from_date
        dataToSend.to_date = date.to_date
  
      }
    let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/customer_invoices`;
   
    
    axios.post(full_api, dataToSend, { headers: exportValue.headers })
          .then((res) => {
            // console.log("responseJson => ", res);
           
            setState(res.data.output)
            setisLoading(false)
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              // console.log(error);
          });    

    }
    const next_step = (sub)=>{
        // navigate({
        //   pathname: '/invoice_detail',
        //   search: `invoice_id=${sub.invoice_id}&invoice_date=${sub.invoice_date}&amount=${sub.amount}&customer_id=${sub.customer_id}`
        // })
        const url = `/invoice_detail?invoice_id=${sub.invoice_id}&invoice_date=${sub.invoice_date}&amount=${sub.amount}&customer_id=${sub.customer_id}`;
        
        // Open the URL in a new tab
        window.open(url, '_blank');
      }
      const dateselect =(e)=>{
        setdate({...date,[e.target.name]:e.target.value})
     }
  return (
    <div>
  <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      
      <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
    
           <div className=''>
        <section class="mt-3">
        <div class="row mb-3">
            <div class="col-12 col-md-4">
                <h5>Invoices</h5>
            </div>
            <div class="col-12 col-md-2">
                    <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="from_date" value={date.from_date}  onChange={(e)=>dateselect(e)}/>
                    </div>
                    <div class="col-12 col-md-2">
                    <input type="date" class="form-control form-control-sm" id="text" placeholder="Enter details name=" name="to_date" value={date.to_date}  onChange={(e)=>dateselect(e)}/>
                    </div>
                    <div class="col-12 col-md-1">
                       <button className='btn btn-primary g-1' onClick={()=>invoice_list()}>Search</button>
                    </div>
        </div>
        {isLoading? 
                        <div style={{ display: 'flex', justifyContent: 'center', marginLeft:"60px", alignItems: 'center',marginTop:"-200px", height: '100vh' }}>
                        <ReactLoading type={"spin"} color={"#0d6efd"} height={40} width={60} />
                        </div>:
            <div class="card card-body border-0 shadow table-wrapper table-responsive ">
                <table class="table table-hover  shipm_tablew">
                    <thead>
                        <tr>
                            <th class="border-gray-200">Invoice No. #</th>
                            <th class="border-gray-200">Customer</th>
                            <th class="border-gray-200">Invoice Date</th>
                            <th class="border-gray-200">Invoice Period</th>
                            <th class="border-gray-200">Invoice Amount</th>
                            <th class="border-gray-200">Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                       
                        {state.map((sub)=>(

                       
                        <tr>
                            <td>
                            <strong>{sub.invoice_id}</strong>
                            </td>  
                            <td>
                                {sub.company_name?sub.company_name:sub.full_name?sub.full_name:""}
                            </td>                      
                            <td>
                                {sub.invoice_date}
                            </td>  
                            <td>
                               {sub.invoice_period}
                            </td>
                            <td>
                                ₹{sub.amount?sub.amount:0}
                            </td>
                            <td class="fw-700">
                                {/* <a href="assets/doc/shipment_report.csv" class="btn btn-outline-primary "><i class="fa fa-file-excel-o" aria-hidden="true"></i></a> */}
                                <a onClick={()=>next_step(sub)} class="btn btn-primary"><i class="fa fa-file-pdf-o" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                         ))}
                    </tbody>
                </table>
            </div> 
}

    </section>
    </div> 
    </section>              




{/* <?php
include('footer.php');
?>   */}
    </div>
  )
}

export default Customer_invoices
